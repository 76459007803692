import React, { useState, useEffect, useRef } from 'react'
import {useRouter} from "next/router";
import {assemble, correctUrlFormatForRouting, createFilterData, formatIdStrings, getAlgoliaIndex, isItUndefined, marketoOptions as options, sortByTypeAndLocation, stripHtml} from '../../../util/DataUtil';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import {Constants} from "../../../util/Constants";
import {useDispatch, useSelector} from "react-redux";
import {setLocalStorageRedux} from "../../../store/localStorageSlice";
import HeaderCrawler from "../header-crawler";
import RenderParents from './header-parents';
import { 
    renderLogin, 
    isSticky, 
    renderCountries,
    handleRouteChange,
    getHamburgerClass,
    triggerChange,
    scrollUp,
    headerLanguageName,
    headerLanguageImage
} from '../../../util/HeaderUtil';
import { getContentBetweenATags } from '../../utils/htmlUtils';
import { findCloudinaryPath } from '../../utils/htmlUtils';
import { Modal } from '@mui/material';
const TalkForm = dynamic(() => import('../../form/talk-form'));
const TalkFormV2 = dynamic(() => import('../../form/talk-form-v2'));
const Algolia = dynamic(() => import('../Algolia'));
const RenderChildrenLevelOne = dynamic(() => import('./header-children-level-one'));
const RenderChildrenLevelTwo = dynamic(() => import('./header-children-level-two'));


const Header = ({ data, lang, globalMetrics, onBg, offBg, toggleShowSearchBg, hideHeader, pageTranslationPaths, countryData, endpoint, screenWidth, urlAlias}) => {
    if(hideHeader == 1){
        return '';
    }    
    const dispatch = useDispatch();
    const [lastScrollTop, setLastScrollTop] = useState((typeof window !== 'undefined' ? window.pageYOffset : 0) || (typeof document !== 'undefined' ? document.documentElement.scrollTop : 0));
    const [languagesShow, setLanguagesShow] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [menu, setMenu] = useState(assemble(data));
    const [showTalkForm, setShowTalkForm] = useState(false);
    const [searchLeft, setSearchLeft] = useState(false);
    const [gray, setGray] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [currentHover, setCurrentHover] = useState({id : -1})
    const [currentChildSelected, setCurrentChildSelected] = useState(menu ? (menu[0]?.children ? menu[0]?.children[0].id  : -1) : -1)
    const [currentParentSelectedName, setCurrentParentSelectedName] = useState('')
    const [showHamburger, setShowHamburger] = useState(false);
    const router = useRouter()
    const [relativeBottom, setRelativeBottom] = useState(false)
    const [blogs, setBlogs] = useState();
    const [loginShow, setLoginShow] = useState(false)
    const [searchPositionTablet, setSearchPositionTablet] = useState('')
    const myLocalStorage = useSelector(setLocalStorageRedux);
    const searchRef = useRef(null);
    const [countries, setCountries] = useState(assemble(countryData));
    const host = endpoint;
    let breakHeaderL = 1280;
    const [hitsPerPage, setHitPerPages] = useState(1000);
    const [algoliaData, setAlgoliaData] = useState([]);
    const [results, setResults] = useState(false);
    const [isShowAllClicked, setIsShowAllClicked] = useState(false);                    
    const [isMobileLandscape, setIsMobileLandscape] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const languageRedux = useSelector(state => state.language.language);
    const [mobileLeftNav, setMobileLeftNav] = useState(false);    
    const menuLogo = getContentBetweenATags(findCloudinaryPath(data, 'Menu Logo'))

    useEffect(() => {
        const handleResize = () => {
          setMobileLeftNav(screenWidth <= 800);
        };
    
        // Set initial state
        setMobileLeftNav(screenWidth <= 800);
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [screenWidth]);  

    useEffect(() => {
        function handleDocumentClick(event) {
            if (openMenu && !event.target.closest('header')) {
                setOpenMenu(false);
            }
        }
    
        document.addEventListener('click', handleDocumentClick);
    
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [openMenu]);

    useEffect(() => {
        const handleResize = () => {
            const screenHeight = window.innerHeight;
            setIsMobileLandscape(screenHeight <= 440);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const setSearchContainerPosition = () => {
        let position = window.innerWidth - document.getElementsByClassName('languages')[0].getBoundingClientRect().left + 14;

        if (window.innerWidth >= 576 && window.innerWidth < 1280){
            if (position > 180){
                position = 180
            }
        }else{
            if (position > 100){
                position = 100
            }
        }

        setSearchPositionTablet(position)
    }

    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    useEffect(() => {
        if(searchInputValue?.length >= 2){
            fetchAlgoliaData();
            setIsShowAllClicked(false);
        }else {
            setAlgoliaData([]);
        }

        let searchContainer = document.getElementsByClassName('search-container')[1]?.clientWidth;
        let suggestionsContainer = document.getElementsByClassName('auto-suggestions-container')[0];
        
        if(screenWidth >= breakHeaderL && searchActive && searchInputValue?.length >= 3 ){
            suggestionsContainer.style.width = `${searchContainer}px`;
        }else if(searchActive && screenWidth < breakHeaderL && searchInputValue?.length >= 3) {
            suggestionsContainer?.removeAttribute('style');
        }
    }, [screenWidth, searchInputValue, searchActive])

    let facetFiltersArray = JSON.stringify([]);

    const fetchAlgoliaData = async () => {
        // setResults((query.length || facetFilters.length || languageFilter.length) !== 0);

        // let url = createFilterData(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, getAlgoliaDatasetValue(0));
        // let url = createFilterData(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, 'globalprod');
        let url = createFilterData([], facetFiltersArray, [], searchInputValue, hitsPerPage, 0, lang, 'global' + getAlgoliaIndex());
        return await fetch(url, options)
        .then((response) => response.json())
        .then(
            newResponse => {
                setAlgoliaData(newResponse);
                setResults(true);
            });
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            const searchContainer = searchRef.current;
            const autoSuggestionsContainer = document.getElementsByClassName('auto-suggestions-container')[0];
            const seeAllButton = document.getElementsByClassName('see-all-button')[0];

            if (
                searchActive &&
                searchContainer &&
                autoSuggestionsContainer &&
                seeAllButton &&
                !searchContainer.contains(event.target) &&
                !autoSuggestionsContainer.contains(event.target) &&
                !seeAllButton.contains(event.target) 
            ) {
                setSearchActive(false);
            }
        };
      
        document.addEventListener('click', handleClickOutside);
        
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [searchActive]);

    useEffect(() => {
        window.addEventListener('scroll', scrollUp(lastScrollTop, setLastScrollTop));
        window.addEventListener('scroll', isSticky);
        window.addEventListener('keyup', keyPress);
        setSearchContainerPosition()

        return () => {
            window.removeEventListener('scroll', scrollUp);
            window.removeEventListener('scroll', isSticky);
            window.removeEventListener('keyup', keyPress);
        };
    });

    useEffect(() => {
        triggerChange(showTalkForm, setShowTalkForm);
    }, [showTalkForm]);

    useEffect(() => {
        toggleShowSearchBg(searchActive)
    })

    function onLeave(){
        if(!menu){
            return;
        }

        if (menu[0] && menu[0].children && menu[0].children[0] && menu[0].children.id){
            setCurrentChildSelected(menu[0].children[0].id)
        }else if (menu[1] && menu[1].children && menu[1].children[1] && menu[1].children.id) {
            setCurrentChildSelected(menu[1].children[1].id)
        }else {
            setCurrentChildSelected(-1)
        }

        setCurrentHover(-1)

        setLanguagesShow(false)
        offBg()

        if (loginShow) {
            setOpenMenu(true)
        }

        setLoginShow(false)
    }

    const clickOnTerm = (search) => {
        setSearchInputValue(search)
        setBlogs([])

        setTimeout(()=>{
            setSearchActive(false)
            setSearchInputValue('')
            offBg()
        }, 1500)
    }

    const keyDown = (e) => {
        if(e.keyCode !== 13){
            return
        }

        router.push({
            pathname: '/global-search-page',
            query: { search : searchInputValue},
        })

        setTimeout(()=>{
            setSearchActive(false)
            setSearchInputValue('')
            offBg()
        }, 1500)
    }

    const renderAutoSuggestions = (data) => {
        if(!data || data?.length == 0) return;

        let sortedSuggestions = sortByTypeAndLocation(data.hits);

        sortedSuggestions = sortedSuggestions.slice(0, 12);        
        return sortedSuggestions.map((item, key) => {
            let type = undefined;                                    
            if (item?.type === 'location') {                
                type = globalMetrics?.field_facility_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.type === 'global_data_centre_locations') {
                type = globalMetrics?.field_global_data_centre_locatio?.[0]?.value || item?.content_type || item?.field_page_type;                
            } else if (item?.type === 'region') {
                type = globalMetrics?.field_region_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.type === 'metro') {
                type = globalMetrics?.field_metro_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.field_page_type === 'Content Hub') {
                type = globalMetrics?.field_content_hub_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.field_page_type === 'Content Sub') {
                type = globalMetrics?.field_content_sub_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.field_page_type === 'Partners') {
                type = globalMetrics?.field_partners_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.field_page_type === 'Insights') {
                type = globalMetrics?.field_insights_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.field_page_type === 'Solutions' || item?.field_page_type === 'Solutions With Card Stepper') {
                type = globalMetrics?.field_solutions_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.type === 'individual_product_template') {
                type = globalMetrics?.field_products_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.type === 'info_template') {
                type = globalMetrics?.field_info_template_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.type === 'press_release') {
                type = globalMetrics?.field_press_release_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else if (item?.content_type === 'Case Studies') {
                type = globalMetrics?.field_case_studies_label?.[0]?.value || item?.content_type || item?.field_page_type;
            } else {                
                type = item?.content_type || item?.field_page_type;
            }
                             
            return <div onClick={() => {
                if(router.asPath.includes('global-search')){
                    setSearchActive(false);
                }        
                const languagePattern = /^\/([a-z]{2}(?:-[a-z]{2})?)\//;                                
                // const cleanUrl = item.url.replace(languagePattern, '/');    
                // let updatedUrl = cleanUrl;
                const cleanUrl = correctUrlFormatForRouting(item?.url);
                let updatedUrl = cleanUrl.url;

                // if (cleanUrl.includes('/zh-hans/')) {
                //     updatedUrl = cleanUrl.replace('/zh-hans/', '/');
                // }else if (cleanUrl.includes('/aisa/')) {
                //     updatedUrl = cleanUrl.replace('/asia/', '/');
                // }
                router.push({
                    pathname: updatedUrl,
                    // query: { search : item.title, cardId: item.objectID},
                });               
            }}            
            className={`suggestion-item ${item?.title?.length > 60 && screenWidth < 1555 ? 'wrap-down' : ''}`} key={`suggestion-item-${key}`}>                                        
                <div className={`suggestion-item-top-wrapper ${item?.title?.length > 105 && (!item?.field_slug && !item?.field_intro ) ? 'display-block' : ''}`} >
                    <span className='suggestion-item-title'>{item?.title}</span>
                    <span className='suggestion-item-type'>{type}</span>
                </div>
                {<p className='suggestion-item-description'>{item?.field_slug ? stripHtml(item.field_slug) : stripHtml(item?.field_intro)}</p>}
            </div>
        })
    }
       
    const getFromAlgolia = async (e) => {
        setSearchInputValue(e.target.value)

        if(e.target.value.length < 3){
            setBlogs([])
            return;
        }

        const url = Constants.algoliaProduction + 'drupal_query_suggestions' + "/?query=" + e.target.value;

        const response = await fetch(url, options)
            .then((response) => response.json())
            .then((algolia) => {
                // setAlgoliaData(algolia.hits);
            });

    }

    function keyPress (e) {
        if(e.key === "Escape") {
            setBlogs([])
            setTimeout(() => {
                setSearchActive(false)
            }, 500)
        }
    }

    useEffect(() => {
        if (searchActive) {
            if (screenWidth < 1280 && isShowAllClicked) {
                document.body.style.overflow = 'auto';
            } else {
                document.body.style.overflow = 'hidden';
            }
        } else {
            document.body.style.overflow = 'auto';
        }
    
        return () => {
            document.body.style.overflow = 'auto'; // Enable scrolling
        };
    }, [searchActive, screenWidth, isShowAllClicked]);
    
    if (typeof window !== "undefined") {
        const suggestionContainer = document.querySelector('.auto-suggestions-wrapper .auto-suggestions-container');
        const suggestionItems = document.querySelectorAll('.auto-suggestions-wrapper .auto-suggestions-container .suggestion-item');
        const seeAllBtn = document.querySelector('.auto-suggestions-wrapper .see-all-button');

        //mobile
        const suggestionContainerMobile = document.querySelector('.auto-suggestions-wrapper .ascm');
        const suggestionItemsMobile = document.querySelectorAll('.auto-suggestions-wrapper .ascm .suggestion-item');
        // const seeAllBtnMobile = document.querySelector('.auto-suggestions-wrapper .sabm');
        const seeAllBtnMobile = document.querySelector('.auto-suggestions-wrapper .see-all-btn-wrapper');

        let totalHeight = 0;
        let totalHeightMobile = 0;

        // Loop through the first 4 suggestion items and calculate their heights
        if(suggestionItems){
            for (let i = 0; i < Math.min(suggestionItems.length, 4); i++) {
                totalHeight += suggestionItems[i].offsetHeight;
            }
        }
        if(suggestionItemsMobile){
            for (let i = 0; i < Math.min(suggestionItemsMobile.length, 4); i++) {
                totalHeightMobile += suggestionItemsMobile[i].offsetHeight;
            }
        }
        if(suggestionContainer){
        // Set the calculated total height as the container's height
        suggestionContainer.style.height = totalHeight + 'px';
        }
        if(seeAllBtn){
            seeAllBtn.style.top = totalHeight + 'px';
        }
        if(suggestionContainerMobile){
            suggestionContainerMobile.style.height = totalHeightMobile + 'px';
        }
        if (!isMobileLandscape) {
            seeAllBtnMobile && (seeAllBtnMobile.style.top = (totalHeightMobile - 1) + 'px');
          } else {
            seeAllBtnMobile && (seeAllBtnMobile.style.top = null);
          }
               
    }
    
    const handleClickSeeAllBtn = () => {        
        const searchQuery = document.querySelector('#header-search-input-desk').value;
        if(router.asPath.includes('global-search')){
            setSearchActive(false);
        }        
        router.push({
            pathname: '/global-search-page',
            query: { search : searchQuery },
        })
    };

    const handleClickSeeAllBtnMobile = () => {        
        const searchQuery = document.querySelector('#header-search-input-mob').value;
        if(router.asPath.includes('global-search')){
            setSearchActive(false);
        }        
        router.push({
            pathname: '/global-search-page',
            query: { search : searchQuery },
        })
    };

    const handleModalOpen = () => {
        setShowTalkForm(true);
    };
    
    const handleModalClose = () => {
        setShowTalkForm(false);
    };

    return (                
        <>        
            <header
                onMouseLeave={() => onLeave()}
            >
                <HeaderCrawler crawlerMenu={menu} />
                <div className={'mobile relative'}>
                    {
                        loginShow &&
                        <div className={'login-items'}>
                            <div className={'items'}>
                                {renderLogin(true, globalMetrics)}
                            </div>
                            <div onClick={() => setShowTalkForm(true)} className={'talk'}>{isItUndefined(globalMetrics.field_marketo_header_button_text)}</div>
                        </div>
                    }
                    {
                        currentParentSelectedName !== '' &&
                        <div className={'parent-selected'}>
                            <img onClick={() => {
                                setCurrentHover(-1);
                                setCurrentParentSelectedName('');
                                setShowHamburger(false);
                                setOpenMenu(!openMenu); //dxp-2449                 
                        
                                if (loginShow) {
                                    setOpenMenu(false)
                                }

                                setLoginShow(false)
                            }} className={'arrow'} alt={'arrow'} src={'/images/close-icon.svg'} />
                            <span>
                                {currentParentSelectedName}
                                <div className={'activated green'} />
                            </span>
                        </div>
                    }<Image layout='fill' onClick={() => {
                    router.push('/')
                }} className={'img-header-link logo'} alt={'logo'} src={menuLogo ? menuLogo : '/images/logo.png'} />

                    <div className={'right'}>
                        {/*<div onClick={() => setShowTalkForm(true)} className={'contact'}>Contact Us</div>*/}                    
                        <div
                            // style={{right: searchPositionTablet + 'px'}}
                            className={searchActive ? 'search-container active' + (searchLeft ? ' search-left' : '') : 'search-container'}
                            // onClick={() => {
                            //     setSearchActive(true)
                            // }}
                            >
                            <Algolia clickOnTerm={clickOnTerm} data={blogs} />

                            <div className={'helper'}
                                // style={{marginRight : searchActive && searchCoord ? searchCoord - 32.5 + 'px' : ''}}
                            >
                                <input ref={searchRef} id={'header-search-input-mob'} onKeyDown={keyDown} value={searchInputValue} placeholder={isItUndefined(globalMetrics.field_global_search_placeholder)} className={searchActive ? 'search active'  : 'search'} name={'search'} type={'text'} onChange={ (e)=> getFromAlgolia(e)} />
                                {
                                    !openMenu &&
                                    <img alt='search' className={'searchImg'} src={'/images/search.png'}
                                        onMouseEnter={() => setLanguagesShow(false)}
                                        onClick={() => {
                                            setSearchActive(true)
                                            // onBg()
                                            toggleShowSearchBg()
                                            document.getElementById("header-search-input-mob").focus();

                                        }}
                                    />
                                }
                            </div>
                            <div onClick={() => {
                                setBlogs([])
                                setSearchActive(false)                            
                                setIsShowAllClicked(false)
                                setSearchInputValue('');
                                // offBg()
                            }} className={searchActive ? 'escape active' : 'escape'}>
                                <img width={'auto'} height={'auto'} alt='close-icon' src={'/images/close-icon.svg'}/>
                            </div>
                        </div>                                    
                            <div className={'languages'}
                                onClick={() => {
                                    setLanguagesShow(!languagesShow)
                                    setLoginShow(false)
                                    if (languagesShow){
                                        offBg()
                                    }else{
                                        onBg()
                                    }
                                }}
                            >                            
                                {/* <div className={'header-language-name'}>
                                    {headerLanguageName()}
                                </div> */}
                                {
                                    languagesShow &&
                                    <div className={'lang-container'}>
                                        <div className={'global'}>
                                            {renderCountries(countries, true, pageTranslationPaths, host, formatIdStrings)}
                                        </div>
                                        <div className={'regions'}>
                                            {renderCountries(countries, false, pageTranslationPaths, host, formatIdStrings)}
                                        </div>
                                    </div>
                                }
                            </div>
                        
                        <button onClick={() => {       
                            if(!currentParentSelectedName){
                                setOpenMenu(!openMenu); 
                                setShowHamburger(false); 
                                onLeave();
                                setCurrentParentSelectedName('');                        
                            }else{
                                setCurrentHover(-1);
                                setCurrentParentSelectedName('');
                                setShowHamburger(false);
                                if (loginShow) {
                                    setOpenMenu(true)
                                }
                                setLoginShow(false)
                            }                                                                     
                        }}
                            className={getHamburgerClass()} type="button">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                        <div onClick={() => {
                             if (urlAlias === 'contact') {
                                const contactForm = document.querySelector('.contact-us-form');
                                if (contactForm) {
                                    const headerOffset = 94;
                                    const elementPosition = contactForm.getBoundingClientRect().top;
                                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: 'smooth'
                                    });
                                }
                            } else {
                                setShowTalkForm(true);
                            }
                        }} className={'contact'}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">                        
                                <g clip-path="url(#clip0_13319_47371)">
                                    <path d="M5 14V13H4H1H0V12V1V0H1H15H16V1V12V13H15H9.5L6 15.3344L5 16V14.7969V14ZM5 12H6V13V14.1313L8.94375 12.1687L9.19687 12H9.5H15V1H1V12H5ZM4.5 4.5H11.5H12V5.5H11.5H4.5H4V4.5H4.5ZM4.5 7.5H8.5H9V8.5H8.5H4.5H4V7.5H4.5Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_13319_47371">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div> 
                    </div>
                    <div className={openMenu ? 'mobile-parents active' : 'mobile-parents'}>
                        <RenderParents 
                            menu={menu}
                            mobile={true}
                            currentHover={currentHover}
                            gray={gray}
                            setGray={setGray}
                            setShowHamburger={setShowHamburger}
                            setCurrentHover={setCurrentHover}
                            setCurrentParentSelectedName={setCurrentParentSelectedName}
                            onBg={onBg}
                            setCurrentChildSelected={setCurrentChildSelected}
                            router={router}
                            onLeave={onLeave}
                            setOpenMenu={setOpenMenu}
                            setLoginShow={setLoginShow}
                            languagesShow={languagesShow}
                            setLanguagesShow={setLanguagesShow}
                            openMenu={openMenu}
                            offBg={offBg}
                        />
                        {/* <div onClick={() => setShowTalkForm(true)} className={'talk'}>{isItUndefined(globalMetrics.field_marketo_header_button_text)}</div> */}
                        {/* <div onClick={handleModalOpen} className={'talk'}>{isItUndefined(globalMetrics.field_marketo_header_button_text)}</div> */}
                    </div>
                    <div className={'bottom'}>
                        <div className={'menu-container'}>
                            {
                                (currentHover.id && currentHover.children) &&
                                    <div className={'children-container'}>
                                        <div className={'child-flex'}>
                                            <div className={'children-left'}>
                                                <RenderChildrenLevelOne
                                                    mobile={true}
                                                    menu={menu}
                                                    currentHover={currentHover}
                                                    currentChildSelected={currentChildSelected}
                                                    setCurrentChildSelected={setCurrentChildSelected}
                                                    setOpenMenu={setOpenMenu}
                                                    onLeave={onLeave}
                                                    setCurrentParentSelectedName={setCurrentParentSelectedName}
                                                    mobileLeftNav={mobileLeftNav}
                                                    globalMetrics={globalMetrics}
                                                />
                                            </div>
                                            <div className={'children-right'}>
                                                <RenderChildrenLevelTwo
                                                    menu={menu}
                                                    currentHover={currentHover}
                                                    currentChildSelected={currentChildSelected}
                                                    setOpenMenu={setOpenMenu}
                                                    setCurrentParentSelectedName={setCurrentParentSelectedName}
                                                    onLeave={onLeave}
                                                    router={router}
                                                    globalMetrics={globalMetrics}
                                                />
                                            </div>
                                        </div>
                                        <div onClick={() => setShowTalkForm(true)} className={'talk'}>{isItUndefined(globalMetrics.field_marketo_header_button_text)}</div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={'desktop relative'}>

                    <Image layout='fill' onClick={() => {
                        router.push('/')
                    }} className={searchActive ? 'img-header-link logo active' + (searchLeft ? ' search-left' : '') : 'img-header-link logo'} alt={'logo'} src={menuLogo ? menuLogo : '/images/logo.png'} onMouseEnter={ ()=> onLeave()} />
                    <div className={'bottom'}>
                    {searchInputValue && searchActive && <div className='auto-suggestions-wrapper'>
                    {screenWidth >= 1280 && <div className='div-helper-left'></div>}
                    <div className={`auto-suggestions-container`}>
                        {algoliaData?.hits?.length > 0 && renderAutoSuggestions(algoliaData)}                    
                    </div>
                    {screenWidth > 1280 && <div className='div-helper-right'></div>}
                    {isItUndefined(globalMetrics?.field_see_all_text) && algoliaData?.hits?.length >= 1 && <span onClick={() => handleClickSeeAllBtn()} className='see-all-button suggestion-item'>{isItUndefined(globalMetrics?.field_see_all_text)}</span>}
                    </div>}
                        <div
                            className={searchActive ? 'search-container active' + (searchLeft ? ' search-left' : '') : 'search-container'}
                        >
                            <Algolia clickOnTerm={clickOnTerm} data={blogs} />
                            <div className={'helper'}
                                // style={{marginRight : searchActive && searchCoord ? searchCoord - 32.5 + 'px' : ''}}
                            >
                                <input ref={searchRef} id={'header-search-input-desk'} onKeyDown={keyDown} value={searchInputValue} placeholder={isItUndefined(globalMetrics.field_global_search_placeholder)} className={searchActive ? 'search active' : 'search'} name={'search'} type={'text'} onChange={ (e)=> getFromAlgolia(e)}/>
                                {
                                    searchActive &&
                                    <img alt='search' className={'searchImgActive'} src={'/images/search.png'}
                                        // onMouseEnter={() => setLanguagesShow(false)}
                                    />
                                }
                            </div>
                            <div onClick={() => {
                                setBlogs([])
                                setSearchActive(false)
                                setIsShowAllClicked(false)
                                setSearchInputValue('');
                                // offBg()
                            }} className={searchActive ? 'escape active' : 'escape'}>
                                <img width={'auto'} height={'auto'} alt='close-icon' src={'/images/close-icon.svg'}/>
                            </div>
                        </div>
                        <div className={'menu-container'}>
                            <RenderParents 
                                menu={menu}
                                mobile={false}
                                currentHover={currentHover}
                                gray={gray}
                                setGray={setGray}
                                setShowHamburger={setShowHamburger}
                                setCurrentHover={setCurrentHover}
                                setCurrentParentSelectedName={setCurrentParentSelectedName}
                                onBg={onBg}
                                setCurrentChildSelected={setCurrentChildSelected}
                                router={router}
                                onLeave={onLeave}
                                setOpenMenu={setOpenMenu}
                                setLoginShow={setLoginShow}
                                languagesShow={languagesShow}
                                setLanguagesShow={setLanguagesShow}
                                openMenu={openMenu}
                                offBg={offBg}
                            />
                            {
                                (currentHover.id && currentHover.children) &&
                                <div className={'children-container inner-container'} onMouseEnter={() => {
                                    setGray(true)
                                }}>
                                    <div className={'child-flex'}>
                                        <div className={'children-left'}>
                                            <RenderChildrenLevelOne
                                                mobile={false}
                                                menu={menu}
                                                currentHover={currentHover}
                                                currentChildSelected={currentChildSelected}
                                                setCurrentChildSelected={setCurrentChildSelected}
                                                setOpenMenu={setOpenMenu}
                                                onLeave={onLeave}
                                                setCurrentParentSelectedName={setCurrentParentSelectedName}
                                                globalMetrics={globalMetrics}
                                                mobileLeftNav={mobileLeftNav}
                                            />
                                        </div>
                                        <div className={'children-right'}>
                                            <RenderChildrenLevelTwo
                                                menu={menu}
                                                currentHover={currentHover}
                                                currentChildSelected={currentChildSelected}
                                                setOpenMenu={setOpenMenu}
                                                setCurrentParentSelectedName={setCurrentParentSelectedName}
                                                onLeave={onLeave}
                                                router={router}
                                                globalMetrics={globalMetrics}
                                            />
                                        </div>
                                    </div>
                                    <div onClick={() => setShowTalkForm(true)} className={'talk'}>{isItUndefined(globalMetrics.field_marketo_header_button_text)}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={'right'}
                        onMouseEnter={() => onLeave()}
                    >
                        {
                            !searchActive &&
                            <img alt='search'  className={'searchImg'} src={'/images/search.png'}
                                // onMouseEnter={() => setLanguagesShow(false)}
                                onClick={() => {
                                    setSearchActive(true)                                  
                                    document.getElementById("header-search-input-desk").focus();
                                }}
                            />
                        }                    
                        <div className={`languages ${['ch', 'asia', 'en'].includes(lang) ? 'languages-square-flag' : ''}`}
                            onClick={() => {
                                setLanguagesShow(!languagesShow)
                                setLoginShow(false)

                                if (languagesShow){
                                    offBg()
                                }else{
                                    onBg()
                                }
                            }}
                        >
                            <img alt='globe-icon' className={'globe'} src={'/images/flags/' + headerLanguageImage(countryData, lang)} />
                            {headerLanguageName(countryData, lang)}
                            {
                                languagesShow &&
                                <div className={'lang-container'}>
                                    <div className={'global'}>
                                        {renderCountries(countries, true, pageTranslationPaths, host, formatIdStrings)}
                                    </div>
                                    <div className={'regions'}>
                                        {renderCountries(countries, false, pageTranslationPaths, host, formatIdStrings)}
                                    </div>
                                </div>
                            }
                        </div>
                        <label
                            onClick={() => {
                                setLoginShow(!loginShow)
                                setLanguagesShow(false)
                                if (loginShow){
                                    offBg()
                                }else{
                                    onBg()
                                }
                            }}
                            className={'login'}>Login
                            {
                                loginShow &&
                                <div className={'login-items'}>
                                    <span className={'login-title'}>Login</span>
                                    <div className={'items'}>
                                        {renderLogin(false, globalMetrics)}
                                    </div>
                                </div>
                            }
                        </label>
                        <div onClick={() => {
                            if (urlAlias === 'contact') {
                                const contactForm = document.querySelector('.contact-us-form');
                                if (contactForm) {
                                    const headerOffset = 94;
                                    const elementPosition = contactForm.getBoundingClientRect().top;
                                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: 'smooth'
                                    });
                                }
                            } else {
                                setShowTalkForm(true);
                            }
                        }} className={'talk'}>{isItUndefined(globalMetrics.field_marketo_header_button_text)}</div>
                        {/* <div onClick={handleModalOpen} className={'talk'}>{isItUndefined(globalMetrics.field_marketo_header_button_text)}</div> */}
                    </div>
                </div>

                {/* NEW CONNECT WITH US FORM */}
                <Modal
                    open={showTalkForm}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{width:'100%', height:'100%'}}
                >
                    <div className={isSubmitted ? 'main-marketo-v2' : 'main-marketo-v2'}>
                        <TalkFormV2 lang={lang} setShowTalkForm={setShowTalkForm} showTalkForm={showTalkForm} globalMetrics={globalMetrics} width={screenWidth} setIsSubmitted={setIsSubmitted}/>
                    </div>
                </Modal>

                 {/*<TalkForm lang={lang} setShowTalkForm={setShowTalkForm} showTalkForm={showTalkForm} globalMetrics={globalMetrics} />*/}
            </header>        
            {searchInputValue && searchActive && screenWidth < 1280 && <div className='auto-suggestions-wrapper'>
            <div className={`auto-suggestions-container ascm`}>
                {algoliaData?.hits?.length > 0 && renderAutoSuggestions(algoliaData)}                    
            </div>
            {screenWidth > 1280 && <div className='div-helper-right'></div>}                
            {isItUndefined(globalMetrics?.field_see_all_text)  && algoliaData?.hits?.length >= 1 && <div className={isMobileLandscape ? `see-all-btn-wrapper see-all-btn-wrapper-landscape` : `see-all-btn-wrapper`}><span onClick={() => handleClickSeeAllBtnMobile()} className='see-all-button sabm suggestion-item'>{isItUndefined(globalMetrics?.field_see_all_text)}</span></div>}                
            </div>}
        </>
    )
}

export default Header;