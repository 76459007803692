import React, { useEffect, useState } from 'react';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import Flag from 'react-flagkit';
import { getCookie } from 'cookies-next';

// Country options
const countries = [
    { value: '', label: 'Select ...', code: '' },
    { value: 'US', label: 'United States', code: 'US' },
    { value: 'GB', label: 'United Kingdom', code: 'GB' },
    { value: 'AL', label: 'Albania', code: 'AL' },
    { value: 'AD', label: 'Andorra', code: 'AD' },
    { value: 'AM', label: 'Armenia', code: 'AM' },
    { value: 'AU', label: 'Australia', code: 'AU' },
    { value: 'AT', label: 'Austria', code: 'AT' },
    { value: 'BY', label: 'Belarus', code: 'BY' },
    { value: 'BE', label: 'Belgium', code: 'BE' },
    { value: 'BA', label: 'Bosnia and Herzegovina', code: 'BA' },
    { value: 'BR', label: 'Brussels', code: 'BE' },
    { value: 'BG', label: 'Bulgaria', code: 'BG' },
    { value: 'CA', label: 'Canada', code: 'CA' },
    { value: 'CN', label: 'China', code: 'CN' },
    { value: 'HR', label: 'Croatia', code: 'HR' },
    { value: 'CY', label: 'Cyprus', code: 'CY' },
    { value: 'CZ', label: 'Czech Republic', code: 'CZ' },
    { value: 'DK', label: 'Denmark', code: 'DK' },
    { value: 'EE', label: 'Estonia', code: 'EE' },
    { value: 'FI', label: 'Finland', code: 'FI' },
    { value: 'FR', label: 'France', code: 'FR' },
    { value: 'GE', label: 'Georgia', code: 'GE' },
    { value: 'DE', label: 'Germany', code: 'DE' },
    { value: 'GR', label: 'Greece', code: 'GR' },
    { value: 'HK', label: 'Hong Kong', code: 'HK' },
    { value: 'HU', label: 'Hungary', code: 'HU' },
    { value: 'IS', label: 'Iceland', code: 'IS' },
    { value: 'ID', label: 'Indonesia', code: 'ID' },
    { value: 'IE', label: 'Ireland', code: 'IE' },
    { value: 'IT', label: 'Italy', code: 'IT' },
    { value: 'JP', label: 'Japan', code: 'JP' },
    { value: 'LV', label: 'Latvia', code: 'LV' },
    { value: 'LT', label: 'Lithuania', code: 'LT' },
    { value: 'LU', label: 'Luxembourg', code: 'LU' },
    { value: 'MK', label: 'North Macedonia', code: 'MK' },
    { value: 'MY', label: 'Malaysia', code: 'MY' },
    { value: 'MX', label: 'Mexico', code: 'MX' },
    { value: 'MC', label: 'Monaco', code: 'MC' },
    { value: 'NZ', label: 'New Zealand', code: 'NZ' },
    { value: 'KP', label: 'North Korea', code: 'KP' },
    { value: 'NO', label: 'Norway', code: 'NO' },
    { value: 'OM', label: 'Other', code: 'OM' },
    { value: 'PW', label: 'Palau', code: 'PW' },
    { value: 'PG', label: 'Papua New Guinea', code: 'PG' },
    { value: 'PH', label: 'Philippines', code: 'PH' },
    { value: 'PL', label: 'Poland', code: 'PL' },
    { value: 'PT', label: 'Portugal', code: 'PT' },
    { value: 'RO', label: 'Romania', code: 'RO' },
    { value: 'RU', label: 'Russia', code: 'RU' },
    { value: 'SM', label: 'San Marino', code: 'SM' },
    { value: 'GB', label: 'Scotland', code: 'GB' },
    { value: 'SG', label: 'Singapore', code: 'SG' },
    { value: 'SK', label: 'Slovakia', code: 'SK' },
    { value: 'SI', label: 'Slovenia', code: 'SI' },
    { value: 'WS', label: 'Samoa', code: 'WS' },
    { value: 'KR', label: 'South Korea', code: 'KR' },
    { value: 'ES', label: 'Spain', code: 'ES' },
    { value: 'SE', label: 'Sweden', code: 'SE' },
    { value: 'CH', label: 'Switzerland', code: 'CH' },
    { value: 'TW', label: 'Taiwan', code: 'TW' },
    { value: 'TH', label: 'Thailand', code: 'TH' },
    { value: 'NL', label: 'Netherlands', code: 'NL' },
    { value: 'TR', label: 'Turkey', code: 'TR' },
    { value: 'UA', label: 'Ukraine', code: 'UA' },
    { value: 'VN', label: 'Vietnam', code: 'VN' },
    { value: 'GB', label: 'Wales', code: 'GB' },
    { value: 'EU', label: 'Western Europe (Other)', code: 'EU' }
];

// US State options
const states = [
    { value: 'AK', label: 'Alaska' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
];

const formatOptionLabel = ({ label, code }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {
            code &&
            <Flag country={code} style={{ marginRight: 10, boxSizing: 'content-box' }} />
        }
        {label}
    </div>
);

const CaretDownIcon = () => {
    return <img alt={'arrow-down'} src={'/images/arrow-down.png'} />
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon/>
        </components.DropdownIndicator>
    );
};

const CustomDropdown = ({
    setCustomDropdownValue,
    options,
    fieldId,
    label,
    formId
}) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [requiredField, setRequiredField] = useState(false);

    const handleChange = (fieldId, selectedOption) => {
        setSelectedCountry(selectedOption);
        
        const originalSelect = document.getElementById(fieldId);
        if (originalSelect) {
            if(fieldId === 'Country'){
                originalSelect.value = selectedOption?.label || '';
            } else {
                originalSelect.value = selectedOption?.value || '';
            }
    
            const event = new Event('change', { bubbles: true });
            originalSelect.dispatchEvent(event);
    
            if (window.MktoForms2) {
                const form = window.MktoForms2.getForm(formId);

                if (form) {
                    form.setValues({
                        [fieldId]: selectedOption?.value || ''
                    });
    
                    const formValues = form.vals();
                    console.log('Current form values:', formValues);
                } else {
                    console.error('Cant find marketo form.');
                }
            } else {
                console.error('MktoForms2 is not initialized.');
            }
        }

        setCustomDropdownValue(
            {
                name: fieldId,
                value: fieldId === 'Country' ? selectedOption?.label : selectedOption?.value
            }
        );
    };

    useEffect(() => {
        const marketoIsSet = getCookie('marketoissetondlr');
        const marketoVals = getCookie('marketovals');
    
        if (marketoIsSet && marketoVals) {
          const parsedVals = JSON.parse(marketoVals);

          if (fieldId === 'Country') {
            const defaultCountry = countries.find(country => country.label === parsedVals.Country);
            setSelectedCountry(defaultCountry);
          }else if (parsedVals[fieldId]) {
            const defaultValue = options.find(option => option.value === parsedVals[fieldId]);
            setSelectedCountry(defaultValue);
          }
        }
      }, [fieldId]);
    

    return (
        <>
            <div id={'custom-dropdown-wrapper'} className={requiredField ? "mktoFormCol addHover removeError" : "mktoFormCol addHover"} style={{ marginBottom: '10px' }}>
                <div className="mktoOffset" style={{ width: '10px' }} />
                <div className="mktoFieldWrap mktoRequiredField">
                    <label htmlFor="custom-dropdown" id={fieldId} className="mktoLabel mktoHasWidth active" style={{width: '100px', zIndex: '1'}}>
                        <div className="mktoAsterix">*</div>
                        {label}
                    </label>
                    <div className="mktoGutter mktoHasWidth" style={{width: '10px'}}></div>
                    <Select
                        id="custom-dropdown"
                        name={fieldId}
                        // options={countries}
                        // options={options}
                        options={fieldId.includes('Country') ? countries : options}
                        formatOptionLabel={formatOptionLabel}
                        placeholder={options[0]?.label}
                        onChange={(selectedOption) => handleChange(fieldId, selectedOption)}
                        className="mktoField mktoHasWidth mktoRequired mktoValid"
                        components={{DropdownIndicator}}
                        value={selectedCountry}
                        // menuIsOpen
                        styles={{
                            control: (base) => ({
                                ...base,
                                boxShadow: 'none',
                                border: 0
                            }),
                        }}
                    />
                    {
                        requiredField &&
                        <div class="mktoError removeError">
                            <div class="mktoErrorArrowWrap">
                                <div class="mktoErrorArrow"></div>
                            </div>
                            <div id="ValidMsgCompany_HQ_Country__c" role="alert" tabindex="-1" class="mktoErrorMsg">This field is required.</div>
                        </div>
                    }
                    <span id="InstructCompanyHQCountry" tabIndex="-1" className="mktoInstruction"></span>
                    <div className="mktoClear"></div>
                </div>
                <div className="mktoClear"></div>
            </div>
            {selectedCountry?.value === 'UNITED STATES' && (
                <div id={'custom-dropdown-states'} className={requiredField ? "mktoFormCol addHover removeError" : "mktoFormCol addHover"} style={{ marginBottom: '10px' }}>
                    <div className="mktoOffset" style={{ width: '10px' }} />
                    <div className="mktoFieldWrap">
                        <label htmlFor="company-hq-state" id="LblCompanyHQState" className="mktoLabel mktoHasWidth" style={{ width: '100px', zIndex: '9' }}>
                            <div className="mktoAsterix">*</div> Customer HQ State
                        </label>
                        <div className="mktoGutter mktoHasWidth" style={{ width: '10px' }}></div>
                        <Select
                            id="custom-dropdown"
                            name="company_hq_state"
                            options={states}
                            placeholder="Select a state..."
                            className="mktoField mktoHasWidth mktoValid"
                            value={selectedCountry}
                            // menuIsOpen
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: 0
                                }),
                            }}
                        />
                        <span id="InstructCompanyHQState" tabIndex="-1" className="mktoInstruction"></span>
                        <div className="mktoClear"></div>
                    </div>
                    <div className="mktoClear"></div>
                </div>
            )}
        </>
    );
};

export default CustomDropdown;