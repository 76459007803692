import React from "react";
import Marketo from "../marketo";
import {getEditorColors, isItUndefined, modifyLinks, renderTextColor} from "../../util/DataUtil";
import CustomCountrySelector from "../form/custom-dropdown";

const RegisterLeadMarketo = (data) => {
    const emphasizedTextColor = getEditorColors(isItUndefined(data?.children?.field_marketo_main_title), isItUndefined(data?.children?.field_marketo_main_description)).filter(x => x)[0];
    return (
        <div id={"register-lead"} className={'register-lead'}>
            <div className={'container inner-container'}>
                <div className={'form-right'}>
                    <Marketo lang={data?.lang} global={data?.global}>{{...data?.children, lang : data?.lang}}</Marketo>
                </div>
            </div>
        </div>
    );
}

export default RegisterLeadMarketo;